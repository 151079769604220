import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Toast } from "bootstrap";
const initialState = {
  salonRequest: [],
  isLoading: false,
  isSkeleton: false,
};

export const getSalonRequest = createAsyncThunk(
  "admin/salonRequest/getallsalonRequest",
  async (payload) => {
    return apiInstance.get(`admin/salonRequest/getallsalonRequest`);
  }
);

export const attendExpert = createAsyncThunk(
  "admin/attendance/attendanceByAdmin",
  async (id) => {
    return apiInstance.post(
      `admin/attendance/attendanceByAdmin?expertId=${id}`
    );
  }
);

export const salonRequestAccept = createAsyncThunk(
  "admin/salonRequest/acceptSalonRequest",
  async (payload) => {
    return await apiInstance.post(
      `admin/salonRequest/acceptSalonRequest?requestId=${payload}`
    );
  }
);

export const absentExpert = createAsyncThunk(
  "admin/attendance/addAbsentByAdmin",
  async (id) => {
    return apiInstance.post(`admin/attendance/addAbsentByAdmin?expertId=${id}`);
  }
);

const salonRequestSlice = createSlice({
  name: "attendanceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalonRequest.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSalonRequest.fulfilled, (state, action) => {
      console.log("action?.payload?.data", action?.payload?.data);
      state.salonRequest = action?.payload?.data;
      state.isSkeleton = false;
    });

    builder.addCase(getSalonRequest.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(attendExpert.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(attendExpert.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.attendance = action?.payload?.data;
        Success("Expert attendance Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(attendExpert.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(absentExpert.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(absentExpert.fulfilled, (state, action) => {
      state.isLoading = false;
      state.attendance = action?.payload?.data;

      if (action?.payload?.status) {
        Success("Expert attendance Updated Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(salonRequestAccept.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(salonRequestAccept.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.status === true) {
        state.salonRequest = state.salonRequest?.filter(
          (item) => item._id !== action.meta?.arg
        );

        Success("Salon  Request Accepted Successfully");
      }
    });

    builder.addCase(salonRequestAccept.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default salonRequestSlice.reducer;
